import { Injectable, Injector } from '@angular/core';
import { BitfJwtAuthService } from '@bitf/services/auth/jwt/bitf-jwt-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfJwtAuthService {
  tokenAlertTresholdMinutes = 5;
  constructor(protected injector: Injector) {
    super(injector);
  }

  handleAuthentication() {}
}
