import { BitfApiRequestPart } from './bitf-api-request-part';
import { EApiRequestPartKeys, EBitfApiRequestPartsNames } from '@enums';

export class BitfSearchApiRequestPart extends BitfApiRequestPart {
  constructor({
    apiRequestPartKey = EApiRequestPartKeys.SEARCH,
    apiRequestPartName = EBitfApiRequestPartsNames.SEARCH,
  } = {}) {
    super(apiRequestPartKey, apiRequestPartName);
  }

  build(): void {
    this.part = this.formValue;
  }
}
