import { CONSTANTS as COMMON_CONSTANTS } from '@common/constants/common.constants';
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { BITF_VALIDATION_MESSAGES } from '@bitf/constants';

export const WEB_CONSTANTS = {
  fullScreenMessageComponent: null,
  okCancelDialogComponent: BitfMatOkCancelDialogComponent,
  VALIDATION_MESSAGES: {
    ...BITF_VALIDATION_MESSAGES,
  },
};

export const CONSTANTS = Object.assign(COMMON_CONSTANTS, WEB_CONSTANTS);
