import { SuperModel } from './super-model.model';

export class User extends SuperModel {
  firstName: string;
  lastName: string;
  email: string;
  constructor(data = {}) {
    super(data);
  }
  get serialised() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
    };
  }

  get canViewLogs() {
    return true;
  }
}
